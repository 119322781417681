const WizardUtilitiesInjectionKey = Symbol("Wizard state injection key");

/*
That Hook is only used to handle back cta automatically in Wizard steps
without having to enable it manually for each step.
 */

export type WizardUtilities = {
  send({ type }: { type: string }): void;
  state: Ref<{ can({ type }: { type: string }): boolean }>;
};

export function useWizardUtilities() {
  return inject<WizardUtilities>(WizardUtilitiesInjectionKey);
}

export function provideWizardUtilities(utilities: WizardUtilities) {
  provide(WizardUtilitiesInjectionKey, utilities);
}
