<template>
  <app-wizard-step
    :cover="cover"
    :disabled="disabled"
    :title="$t('onboarding.persona_selection.title')"
    @submit="
      emit('send', {
        type: 'personas_selected',
        personas: selectedPersonasArray,
      })
    "
  >
    <div class="grid gap-4">
      <app-form-input-radio-card
        v-for="item in items"
        :key="item.value"
        v-model="selectedPersonas"
        :data-cy-personas="item.value"
        name="persona"
        :title="item.label"
        :value="item.value"
      />
    </div>

    <img
      v-for="item in items"
      :key="item.cover"
      class="hidden"
      :src="item.cover"
    />
  </app-wizard-step>
</template>

<script lang="ts" setup>
import type { OnboardingWizardEvent } from "~/business-areas/onboarding/onboarding.model";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const emit = defineEmits<{
  send: [event: OnboardingWizardEvent];
}>();

const { me } = useEnrichedCurrentUser();

type StringifiedPersonas =
  | DatabaseEnum<"user_persona">
  | `${DatabaseEnum<"user_persona">}|${DatabaseEnum<"user_persona">}`;

const selectedPersonas = ref<StringifiedPersonas | undefined>(
  me.value?.profile?.personas?.join("|") as StringifiedPersonas,
);
const selectedPersonasArray = computed(
  () =>
    (selectedPersonas.value?.split("|") as DatabaseEnum<"user_persona">[]) ??
    [],
);

const { t } = useI18n();
const items = computed<
  {
    label: I18nMessage;
    value: typeof selectedPersonas.value;
    cover: string;
  }[]
>(() => {
  return [
    {
      label: t("onboarding.persona_selection.manager"),
      value: "MANAGER",
      cover: "/images/illustrations/onboarding/personas_manager.svg",
    },
    {
      label: t("onboarding.persona_selection.learner"),
      value: "LEARNER",
      cover: "/images/illustrations/onboarding/personas_learner.svg",
    },
    {
      label: t("onboarding.persona_selection.learner_and_manager"),
      value: "MANAGER|LEARNER",
      cover: "/images/illustrations/onboarding/personas_learner_manager.svg",
    },
  ];
});

const cover = computed(() => {
  return (
    items.value.find((item) => item.value === selectedPersonas.value)?.cover ??
    "/images/illustrations/onboarding/personas_empty.svg"
  );
});

const disabled = computed(() => !selectedPersonas.value);
</script>
