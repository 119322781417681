<template>
  <app-wizard-step
    :title="$t('onboarding.personal_information.title')"
    @submit="emit('send', { type: 'personal_information_submitted', form })"
  >
    <template #aside>
      <div class="relative">
        <app-image
          alt=""
          class="max-h-[280px] max-w-[300px]"
          src="/images/illustrations/onboarding/personal_information.svg"
        />

        <transition
          appear
          enter-active-class="transition duration-300 origin-bottom-left"
          enter-from-class="scale-75 opacity-0"
          leave-active-class="transition"
          leave-to-class="-translate-y-2 opacity-0"
          mode="out-in"
        >
          <p
            v-if="showMessage"
            :key="message"
            class="balance absolute bottom-[75%] left-[50%] w-max max-w-[270px] rounded-lg rounded-bl-none bg-gradient-to-b from-primary-800 to-primary-900 px-4 py-1 text-sm font-semibold text-white shadow-lg"
          >
            {{ message }}
          </p>
        </transition>
      </div>
    </template>
    <div class="relative grid grid-cols-2 gap-4">
      <app-form-input-text
        v-model="form.first_name"
        data-cy-first-name
        :label="$t('app.form.labels.first_name')"
        prepend-icon="ph:identification-card"
        required
      />

      <app-form-input-text
        v-model="form.last_name"
        data-cy-last-name
        :label="$t('app.form.labels.last_name')"
        prepend-icon="ph:identification-card"
        required
      />

      <app-form-input-text
        v-model="form.company_name"
        autofocus
        class="col-span-2"
        data-cy-company-name
        :label="$t('app.form.labels.company')"
        prepend-icon="ph:buildings"
        required
        @blur="
          form.company_name &&
            updateMessage(`${form.company_name} ? Belle boîte 🚀`)
        "
      />

      <app-form-input-text
        v-model="form.job_title"
        class="col-span-2"
        data-cy-job-title
        :label="$t('app.form.labels.job_title')"
        prepend-icon="ph:planet"
        required
        @blur="
          form.job_title &&
            updateMessage(
              `Et c'est une bonne situation ça, ${form.job_title} ?`,
            )
        "
      />

      <app-form-input-text
        v-model="form.phone_number"
        class="col-span-2"
        data-cy-phone-number
        :label="$t('app.form.labels.phone_number')"
        prepend-icon="ph:device-mobile"
        :required="hasOrganizationMembership"
        :tooltip="
          hasOrganizationMembership
            ? $t('onboarding.personal_information.phone_number_tooltip')
            : undefined
        "
      />

      <app-form-input-checkbox
        v-if="displayNewsletterOptOut"
        class="col-span-2"
        data-cy-newsletter-opt-out
        :label="$t('authentication.newsletter_opt_out_label')"
        :model-value="!form.preferences.newsletter_subscribed"
        @update:model-value="form.preferences.newsletter_subscribed = !$event"
      />
    </div>
  </app-wizard-step>
</template>

<script lang="ts" setup>
import type {
  OnboardingWizardEvent,
  PersonalInformationForm,
} from "~/business-areas/onboarding/onboarding.model";

const emit = defineEmits<{
  send: [event: OnboardingWizardEvent];
}>();

const { me } = useEnrichedCurrentUser();
const { hasOrganizationMembership } = useOrganizationMemberProfile();

const form = reactive<PersonalInformationForm>({
  first_name: me.value?.first_name ?? "",
  last_name: me.value?.last_name ?? "",
  company_name: me.value?.company_name ?? "",
  job_title: me.value?.job_title ?? "",
  phone_number: me.value?.phone_number ?? "",
  preferences: {
    newsletter_subscribed: me.value?.preferences?.newsletter_subscribed ?? true,
  },
});

/*
Only dislay that choice for those who never set it.
We don't use a computed value here as we want the user to be able to change it
by going back on the onboarding after having set it
 */
const displayNewsletterOptOut =
  me.value?.preferences?.newsletter_subscribed === undefined;

const message = ref(`Bienvenue sur Pollen ${form.first_name} 🤗`);
const showMessage = ref(true);

const { start, stop } = useTimeout(2500, {
  controls: true,
  callback: () => (showMessage.value = false),
});

stop();

function updateMessage(text: string) {
  stop();
  message.value = text;
  showMessage.value = true;
  start();
}
</script>
