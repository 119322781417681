<template>
  <app-dialog-full-screen
    v-model="model"
    data-cy-onboarding-dialog
    prevent-auto-close
    @close="onClose"
  >
    <brand-navigation-logo class="absolute hidden md:block" />
    <div class="flex min-h-full">
      <transition
        enter-active-class="transition"
        enter-from-class="opacity-0"
        leave-active-class="transition"
        leave-to-class="opacity-0"
        mode="out-in"
      >
        <keep-alive>
          <component :is="view" :context="state.context" @send="send($event)" />
        </keep-alive>
      </transition>
    </div>
  </app-dialog-full-screen>
</template>

<script lang="ts" setup>
import type { OnboardingWizardOptions } from "~/business-areas/onboarding/onboarding.model";

const properties = defineProps<{
  modelValue: boolean;
  options: Omit<OnboardingWizardOptions, "onComplete">;
}>();

const model = useVModel(properties, "modelValue");
const { close } = useDialog("onboardingDialog");

function onClose() {
  useNuxtApp().$analytics.track("ob_cancelled");
  return close();
}

const { view, send, state } = useOnboardingWizard({
  ...properties.options,
  onComplete() {
    return close({
      confirmed: true,
      payload: {
        context: state.value.context,
      },
    });
  },
});

provideWizardUtilities({ send, state });
</script>
