<template>
  <ul class="flex flex-wrap gap-2">
    <li v-for="tag in listWithShapes" :key="tag.id" class="relative">
      <app-card
        class="flex cursor-pointer items-center gap-3 rounded-lg p-2 font-semibold text-subtle outline-none ring-black/20 transition-all hover:shadow-lg focus-visible:ring-2 md:p-3"
        :class="{
          'border-black !text-black !shadow-relief !shadow-black':
            isSelected(tag),
          '!rounded !p-2 text-sm': size === 'small',
        }"
        :data-cy-tag-id="tag.id"
        outlined
        tabindex="0"
        tag="label"
      >
        <span
          class="grid aspect-square place-content-center text-gray-600"
          :class="{ '!w-6': size === 'small', 'w-7 md:w-10': size !== 'small' }"
          :style="{
            backgroundColor: tag.bgColor,
            color: tag.textColor,
            clipPath: tag.shape,
          }"
        >
          <app-icon
            :class="{
              '!h-4 !w-4': size === 'small',
              '!h-5 !w-5 md:!h-6 md:!w-6': size !== 'small',
            }"
            :icon="tag.icon"
          />
        </span>

        {{ tag.label }}
        <input
          v-model="model"
          class="sr-only"
          tabindex="-1"
          type="checkbox"
          :value="tag.slug"
        />
      </app-card>

      <tag-list-selection-indicator
        v-if="countAnimation"
        class="absolute right-2 top-0"
        :list="model"
        :slug="tag.slug"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import * as shapes from "~/business-areas/brand/brand-shapes";
import TagListSelectionIndicator from "~/business-areas/tag/components/tag-list-selection-indicator.vue";
import { tagColors } from "~/business-areas/tag/composables/tag.utils";

const properties = defineProps<{
  modelValue: DatabaseTable<"tags">["slug"][];
  list: DatabaseTable<"tags">[];
  size?: "small";
  countAnimation?: boolean;
}>();

const model = useVModel(properties, "modelValue");

const listWithShapes = computed(() =>
  properties.list.map((item) => {
    return {
      ...item,
      shape: pickRandomElementInArray(Object.values(shapes)),
      ...tagColors(item),
    };
  }),
);

function isSelected(tag: DatabaseTable<"tags">) {
  return model.value?.includes(tag.slug);
}
</script>
