<template>
  <app-wizard-step
    :cta-label="ctaLabel"
    :title="$t('onboarding.subcategory_selection.title')"
    @submit="onSubmit"
  >
    <template #aside>
      <div class="relative">
        <app-image
          alt=""
          class="max-h-[320px] max-w-[350px]"
          src="/images/illustrations/onboarding/subcategories.svg"
        />

        <transition-group
          class="absolute left-[38%] top-[17%] flex max-h-[47%] w-[36%] flex-wrap gap-1 overflow-hidden p-2"
          enter-active-class="transition"
          enter-from-class="opacity-0 translate-y-1"
          leave-active-class="transition"
          leave-to-class="opacity-0 translate-y-1"
          move-class="transition"
          tag="ul"
        >
          <li
            v-for="tag in selectedTags"
            :key="tag.id"
            class="h-[10px] rounded-sm"
            :style="{
              backgroundColor: hexToHSL(tag.color, {
                luminance: 75,
                saturation: 70,
              }),
              width: `${tag.label.length * 1.5}px`,
            }"
          />
        </transition-group>
      </div>
    </template>

    <div class="grid max-h-[380px] gap-6 overflow-auto">
      <fieldset v-for="section in sections" :key="section.id">
        <legend
          class="mb-3 flex items-center gap-2 text-lg font-bold"
          :style="{
            '--bgColor': section.bgColor,
            '--bdColor': section.bdColor,
            '--textColor': section.textColor,
          }"
        >
          <app-icon
            class="bg-[--bgColor] p-1 text-[--textColor]"
            :icon="section.icon"
            :size="36"
            :style="{
              clipPath: section.iconShape,
            }"
          />
          {{ section.title }}
        </legend>

        <div class="flex flex-wrap gap-2">
          <label
            v-for="child in section.children"
            :key="child.id"
            class="relative cursor-pointer rounded border px-2 py-1 text-sm font-semibold text-subtle transition active:scale-95"
            :class="{
              'border-[--bdColor] bg-[--bgColor] !text-[--textColor]':
                isSelected(child),
            }"
            :data-cy-tag-id="child.id"
            :style="{
              '--bgColor': child.bgColor,
              '--bdColor': child.bdColor,
              '--textColor': child.textColor,
            }"
          >
            {{ child.label }}
            <input
              v-model="model"
              class="sr-only"
              type="checkbox"
              :value="child.slug"
            />

            <tag-list-selection-indicator
              class="absolute right-2 top-0 !text-[--textColor]"
              :list="model"
              :slug="child.slug"
            />
          </label>
        </div>
      </fieldset>
    </div>
  </app-wizard-step>
</template>

<script lang="ts" setup>
import { hexToHSL } from "#imports";
import * as shapes from "~/business-areas/brand/brand-shapes";
import type {
  OnboardingWizardContext,
  OnboardingWizardEvent,
} from "~/business-areas/onboarding/onboarding.model";
import { tagColors } from "~/business-areas/tag/composables/tag.utils";

const properties = defineProps<{
  context: OnboardingWizardContext;
}>();

const emit = defineEmits<{
  send: [event: OnboardingWizardEvent];
}>();

const { data } = await useTagList({
  depth: 1,
  children_of: computed(() =>
    properties.context.interests.map((tag) => tag.path),
  ),
});

const model = ref<DatabaseTable<"tags">["slug"][]>([]);
const selectedTags = computed(() =>
  model.value
    .map((slug) => data.value?.find((tag) => tag.slug === slug))
    .filter(Boolean),
);

const { t } = useI18n();
const ctaLabel = computed(
  () =>
    `${t("app.cta.continue")}${
      selectedTags.value.length > 0 ? ` (${selectedTags.value.length})` : ""
    }`,
);

const sections = computed(() => {
  return properties.context.interests
    .filter((tag) => tag.depth === 0)
    .map((category) => {
      return {
        id: category.id,
        title: category.label,
        icon: category.icon,
        iconShape: pickRandomElementInArray(Object.values(shapes)),
        ...tagColors(category),
        children: data.value
          .filter((subcategory) => subcategory.path.startsWith(category.slug))
          .map((tag) => {
            return {
              ...tag,
              ...tagColors(tag),
            };
          }),
      };
    });
});

function isSelected(tag: DatabaseTable<"tags">) {
  return model.value.includes(tag.slug);
}

function onSubmit() {
  emit("send", {
    type: "tags_selected",
    tags: selectedTags.value,
  });
}
</script>
