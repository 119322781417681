<template>
  <app-wizard-step
    :cta-label="ctaLabel"
    :disabled="selectedTagSlugs.length === 0"
    :title="$t('onboarding.category_selection.title')"
    @submit="onSubmit"
  >
    <template #aside>
      <onboarding-category-selection-illustration
        class="max-h-[280px] max-w-[300px]"
        :selection="selectedTags"
      />
    </template>

    <tag-card-selectable-list
      v-model="selectedTagSlugs"
      count-animation
      :list="data"
    />
  </app-wizard-step>
</template>

<script lang="ts" setup>
import type { OnboardingWizardEvent } from "~/business-areas/onboarding/onboarding.model";

const emit = defineEmits<{
  send: [event: OnboardingWizardEvent];
}>();

const { me } = useEnrichedCurrentUser();
const { data } = await useTagList({ depth: 0 });
const selectedTagSlugs = ref<DatabaseTable<"tags">["slug"][]>(
  data.value
    .filter((tag) => (me.value?.profile?.interests ?? []).includes(tag.slug))
    .map((tag) => tag.slug),
);
const selectedTags = computed(() =>
  selectedTagSlugs.value
    .map((slug) => data.value?.find((tag) => tag.slug === slug))
    .filter(Boolean),
);

const { t } = useI18n();
const ctaLabel = computed(
  () =>
    `${t("app.cta.continue")}${
      selectedTags.value.length > 0 ? ` (${selectedTags.value.length})` : ""
    }`,
);

function onSubmit() {
  emit("send", {
    type: "tags_selected",
    tags: selectedTags.value,
  });
}
</script>
