<template>
  <app-wizard-step
    cover="/images/illustrations/onboarding/additional_information.svg"
    :cta-label="$t('app.cta.validate')"
    :title="$t('onboarding.additional_information.title')"
    @submit="
      emit('send', { type: 'additional_information_provided', value: model })
    "
  >
    <template #aside>
      <div class="relative">
        <app-image
          alt=""
          class="max-h-[280px] max-w-[300px]"
          src="/images/illustrations/onboarding/additional_information.svg"
        />

        <app-fade-transition>
          <div v-show="displayLoadingDots">
            <div class="loader-dots absolute left-[23%] top-[20%]" />
            <div class="loader-dots absolute left-[52%] top-[17%]" />
          </div>
        </app-fade-transition>
      </div>
    </template>

    <app-form-input-textarea
      v-model.trim="model"
      autofocus
      data-cy-additional-information
      hide-label
      :label="$t('onboarding.additional_information.field_label')"
      :placeholder="$t('onboarding.additional_information.field_placeholder')"
      :rows="6"
    />
  </app-wizard-step>
</template>

<script lang="ts" setup>
import type {
  OnboardingWizardContext,
  OnboardingWizardEvent,
} from "~/business-areas/onboarding/onboarding.model";

const properties = defineProps<{
  context: OnboardingWizardContext;
}>();

const emit = defineEmits<{
  send: [event: OnboardingWizardEvent];
}>();

const model = ref(properties.context.additionalInformation);

const displayLoadingDots = ref(false);
const { start } = useTimeout(1000, {
  controls: true,
  callback: () => (displayLoadingDots.value = false),
});

watchDebounced(
  model,
  () => {
    displayLoadingDots.value = true;
    start();
  },
  {
    debounce: 50,
  },
);
</script>

<style scoped>
/* From https://css-loaders.com/dots/ */

.loader-dots {
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}
</style>
