<template>
  <svg
    fill="none"
    height="227"
    viewBox="0 0 271 227"
    width="271"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.6898 122.675V218.998L235.198 218.999L235.198 122.675C235.198 70.2101 191.826 26.3504 139.944 26.3504C88.0622 26.3503 44.6898 70.2101 44.6898 122.675Z"
      fill="#EFEAFD"
    />
    <path
      d="M124.062 40.9353C118.197 1.44936 65.8099 12.7868 71.6742 45.6268"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M110.949 18.3897C90.202 13.5838 81.9116 20.2146 81.9116 20.2146C81.9116 20.2146 65.2011 10.4006 79.3986 2.73771C84.4654 0.00998127 89.6385 8.58413 89.6385 8.58413C89.6385 8.58413 90.6069 2.69225 95.095 1.41021C99.2641 0.2282 105.217 7.61124 105.217 7.61124C105.217 7.61124 108.252 0.98287 111.547 3.12868C114.842 5.27449 114.74 17.6351 110.949 18.3897Z"
      fill="#311461"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.35835"
    />
    <path
      d="M125.602 124.536C129.269 119.267 128.817 111.737 128.817 111.737C128.817 108.474 127.741 92.2894 126.849 79.4667"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M71.1707 45.6437C70.0945 45.0907 68.9119 44.7761 67.7032 44.7213C66.4945 44.6664 65.2883 44.8726 64.1665 45.3259C56.1599 48.4674 61.025 64.7982 72.8453 63.7592C72.8453 63.7592 70.9262 108.608 71.1829 115.563C71.4396 122.519 81.1819 133.3 90.5697 137.114"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M68.9578 55.7647C68.9578 55.7647 71.0481 55.5936 73.3094 58.894"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M134.061 109.659C134.061 109.659 148.987 97.1422 171.955 109.659C194.923 122.176 195.253 152.442 195.253 152.442"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M62.7603 112.788C62.7603 112.788 46.3438 100.455 27.6537 109.659C5.05207 120.783 0.883786 178.845 1.56831 223.34"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M57.5533 149.374C57.5533 149.374 62.2472 156.415 59.1179 157.931C55.9887 159.446 53.1161 154.545 54.1551 156.366C55.1941 158.187 55.9886 171.23 52.8594 173.834C49.7301 176.437 51.8081 198.868 44.7673 201.74C37.7264 204.613 43.2027 191.57 57.5533 181.143C71.9039 170.717 84.4455 158.97 87.5381 158.97"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M86.4865 162.417C86.4865 162.417 82.7094 169.592 89.7503 177.415C97.7691 186.326 107.352 181.999 107.609 174.701"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M106.974 177.415C106.974 177.415 106.974 184.187 100.312 189.798C94.9457 194.308 82.832 212.045 71.4884 223.34"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M86.4865 162.417C86.4865 162.417 88.3201 159.678 89.6158 150.413C90.9115 141.147 91.1804 138.299 94.5053 138.299C97.8301 138.299 100.238 146.11 101.839 150.352C103.441 154.593 101.326 161.243 94.2119 147.284"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M96.5836 139.424C96.5836 139.424 97.0481 135.036 100.055 133.728C103.062 132.42 105.519 133.862 107.609 138.299C109.7 142.737 113.355 152.369 111.521 153.811C109.687 155.254 106.045 151.721 100.446 140.512"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M106.216 135.867C106.216 135.867 108 132.945 110.873 133.997C113.745 135.048 115.701 141.942 116.875 147.944C118.048 153.946 114.821 154.459 111.545 149.056"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M113.061 136.099C113.94 132.664 115.08 129.3 116.472 126.039C116.844 125.2 117.42 124.468 118.146 123.907C118.872 123.346 119.726 122.974 120.632 122.825C121.537 122.676 122.466 122.754 123.333 123.053C124.201 123.351 124.981 123.861 125.603 124.535C128.048 127.799 130.199 141.942 127.167 142.724C123.5 143.69 121.447 135.683 120.395 129.951"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M121.801 135.561C120.116 139.965 119.326 144.661 119.478 149.374C119.735 155.486 120.701 156.94 122.815 157.662C124.93 158.383 135.638 153.542 138.645 157.332C141.652 161.121 138.009 164.104 131.311 164.886C124.612 165.668 122.18 168.015 118.268 170.362C114.357 172.709 110.188 176.364 101.583 173.882"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      class="transition duration-500"
      d="M149.634 223.401C149.101 223.387 148.578 223.252 148.105 223.005C147.632 222.759 147.221 222.408 146.904 221.979C146.587 221.55 146.372 221.054 146.275 220.53C146.178 220.005 146.203 219.465 146.346 218.951C150.086 205.395 161.503 164.605 163.814 162.356C166.601 159.666 267.214 161.133 269.304 165.277C271.395 169.421 256.09 220.21 254.355 223.34C252.802 226.102 170.598 223.951 149.634 223.401Z"
      :fill="
        hexToHSL(lastSelectedTag?.color ?? '#FF4DC2', {
          luminance: 75,
          saturation: 70,
        })
      "
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M142.178 218.951C142.178 218.951 93.7965 219.465 93.1853 220.247C92.5741 221.029 92.4886 221.225 93.1853 221.751C93.8821 222.276 150.298 223.443 150.298 223.443"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M119.33 53.5769C119.33 53.5769 118.414 58.0508 119.184 58.7353C119.954 59.4198 120.321 55.0682 119.856 54.0903"
      fill="#311461"
    />
    <path
      d="M119.33 53.5769C119.33 53.5769 118.414 58.0508 119.184 58.7353C119.954 59.4198 120.321 55.0682 119.856 54.0903"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M96.3634 52.6965C96.3634 52.6965 95.4466 57.1704 96.2289 57.8549C97.0112 58.5394 97.3657 54.1878 96.889 53.2099"
      fill="#311461"
    />
    <path
      d="M96.3634 52.6965C96.3634 52.6965 95.4466 57.1704 96.2289 57.8549C97.0112 58.5394 97.3657 54.1878 96.889 53.2099"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M114.228 60.993C112.991 51.1261 117.216 42.4715 123.667 41.6623C130.118 40.8531 136.35 48.1958 137.588 58.0627C138.826 67.9295 134.6 76.5842 128.149 77.3934C121.699 78.2026 115.466 70.8599 114.228 60.993Z"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M83.1409 60.4335C82.5148 50.509 87.6275 42.1091 94.5604 41.6717C101.493 41.2343 107.621 48.925 108.247 58.8495C108.874 68.7739 103.761 77.1739 96.8278 77.6113C89.8949 78.0487 83.767 70.3579 83.1409 60.4335Z"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M83.4429 54.2246L77.5388 52.1832"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M105.788 90.9079C105.788 90.9079 99.529 90.04 97.4388 84.796C97.4388 84.796 97.206 96.0595 101.387 96.0595"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M114.112 55.2882C112.015 55.0048 109.889 55.0048 107.792 55.2882"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
    <path
      d="M163.789 162.356C163.789 162.356 160.122 161.255 157.959 166.988C155.795 172.721 142.178 218.951 142.178 218.951"
      stroke="#311461"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.97036"
    />
  </svg>
</template>

<script lang="ts" setup>
import { hexToHSL } from "#imports";

const properties = defineProps<{
  selection: DatabaseTable<"tags">[];
}>();

const lastSelectedTag = ref<DatabaseTable<"tags"> | undefined>();

watch(
  () => properties.selection,
  (newValue, oldValue) => {
    lastSelectedTag.value = newValue.find(
      (tag) => !oldValue?.map((o) => o.slug).includes(tag.slug),
    );
  },
);
</script>
