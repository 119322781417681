export function getStateValueStrings(stateValue: unknown): string[] {
  if (typeof stateValue === "string") return [stateValue];

  if (typeof stateValue === "object" && stateValue !== null) {
    const valueKeys = Object.keys(stateValue);

    return [
      ...valueKeys,
      ...valueKeys.flatMap((key) =>
        getStateValueStrings(stateValue[key as keyof typeof stateValue]!).map(
          (s) => key + "." + s,
        ),
      ),
    ];
  }

  return [];
}
