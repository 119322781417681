<template>
  <span
    v-show="lastSelectedTagSlug === slug"
    class="tag-counter font-semibold text-primary-950"
    >+1</span
  >
</template>

<script lang="ts" setup>
const properties = defineProps<{
  slug: DatabaseTable<"tags">["slug"];
  list: DatabaseTable<"tags">["slug"][];
}>();

const lastSelectedTagSlug = ref<string | undefined>();

watch(
  () => properties.list,
  (newValue, oldValue) => {
    lastSelectedTagSlug.value = newValue?.find(
      (tag) => !oldValue?.includes(tag),
    );
  },
);
</script>

<style scoped>
@keyframes tagCountAnimation {
  O% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

.tag-counter {
  animation-name: tagCountAnimation;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}
</style>
